<template>
  <v-container class="pa-0" color="white">
    <div color="white">
      <base-subheading>{{ $t('MeeTeeMeeNgern1.CookiePolicyPrivateHeader') }}</base-subheading>

      <v-row>
        <v-col
          cols="12"
        >
          <p>บริษัท มีที่ มีเงิน จำกัด (“บริษัทฯ”) มีการใช้คุกกี้บนเว็บไซต์ของบริษัทฯ เพื่อช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้บริการ และช่วยให้บริษัทฯ สามารถพัฒนาท่านภาพของบริการให้ตอบสนองต่อความต้องการของท่านมากยิ่งขึ้น</p>
<p>1. คุกกี้คืออะไร
คุกกี้เป็นไฟล์ข้อความขนาดเล็กประกอบด้วยหมายเลขประจำตัวที่เก็บอยู่ในสารบบของเบราว์เซอร์ในอุปกรณ์ของท่านหรือแฟ้มข้อมูลย่อยของโปรแกรม</p>

<p>2. ประเภทของคุกกี้
ประเภทของคุกกี้ที่บริษัทฯ ใช้ประกอบด้วย แต่ไม่จำกัดเพียง รายการต่อไปนี้</p>

<p>- คุกกี้ที่จำเป็นอย่างยิ่ง (Necessary Cookies)
คุกกี้ประเภทนี้จำเป็นสำหรับการทำงานของเว็บไซต์ของบริษัทฯ และไม่สามารถปิดในระบบของบริษัทฯ ได้ โดยจะถูกตั้งค่าเพื่อตอบสนองต่อสิ่งที่ท่านกระทำเท่านั้น ซึ่งรวมถึงคำขอบริการ เช่น การตั้งค่าการกำหนดลักษณะความเป็นส่วนตัว การเข้าสู่ระบบ หรือการกรอกแบบฟอร์ม เพื่อช่วยให้คุณสามารถใช้งานเว็บไซต์ได้อย่างปลอดภัย
</p>
<p>- คุกกี้ประสิทธิภาพ (Performance Cookies)
คุกกี้ประเภทนี้ช่วยให้บริษัทฯ สามารถวิเคราะห์และประมวลผลการใช้งานเว็บไซต์ของผู้ใช้ เช่น จำนวนการเยี่ยมชมเว็บไซต์ หน้าเว็บไซต์ที่ได้รับความนิยม พฤติกรรมการเยี่ยมชมเว็บไซต์ เพื่อให้บริษัทฯ สามารถปรับปรุงและพัฒนาประสิทธิภาพเว็บไซต์ของบริษัทฯ ให้ดีขึ้น หากท่านไม่ยินยอมให้บริษัทฯ ใช้คุกกี้ประเภทนี้ บริษัทฯ จะไม่สามารถวัดผล ประเมิน และพัฒนาเว็บไซต์ได้
</p>
<p>- คุกกี้เพื่อการทำงาน (Functional Cookies)
คุกกี้ประเภทนี้ช่วยให้เว็บไซต์จัดฟังก์ชันการทำงานและปรับให้เหมาะกับผู้ใช้ตามที่ได้เคยตั้งค่าไว้ เช่น ขนาดตัวอักษร และภาษา เป็นต้น ซึ่งคุกกี้ประเภทนี้ตั้งค่าโดยบริษัทฯ หรือผู้ให้บริการที่เป็นบุคคลที่สาม ซึ่งได้ถูกเพิ่มไว้ในหน้าเว็บไซต์ของบริษัทฯ หากท่านไม่ยินยอมให้บริษัทฯ ใช้คุกกี้ประเภทนี้ ท่านอาจใช้งานเว็บไซต์ได้ไม่เต็มประสิทธิภาพ
</p>
<p>- คุกกี้เพื่อการกำหนดเป้าหมายและการโฆษณา (Advertising Cookies)
คุกกี้ประเภทนี้อาจตั้งค่าโดยพันธมิตรด้านโฆษณาผ่านเว็บไซต์ของบริษัทฯ  เพื่อนำเสนอโฆษณาที่เกี่ยวข้องและตรงกับความสนใจของท่าน หากท่านไม่ยินยอมให้บริษัทฯ ใช้คุกกี้ประเภทนี้ บริษัทฯ จะไม่สามารถนำเสนอสิทธิประโยชน์ ประชาสัมพันธ์ และโฆษณา ให้เหมาะสมกับความสนใจของท่านได้
</p>
<p>3. การใช้คุกกี้โดยบุคคลที่สาม (Third-Party Cookies)
เว็บไซต์ของบริษัทฯ มีการใช้คุกกี้ของบุคคลที่สาม การใช้งานคุกกี้ของบุคคลที่สามนั้นมีลักษณะเช่นเดียวกับ การใช้งานคุกกี้ ที่อธิบายไว้ในส่วนที่ 2 อย่างไรก็ตามท่านไม่สามารถควบคุมการใช้งานคุกกี้โดยบุคคลที่สามผ่านหน้าเว็บไซต์ของบริษัทฯ ได้ ท่านสามารถศึกษารายละเอียดเกี่ยวกับประกาศความเป็นส่วนตัว นโยบายการใช้คุกกี้และการทำงานอื่น ๆ ตามแต่ละเว็บไซต์ของบุคคลที่สามนั้น ๆ ที่ท่านได้เข้าเยี่ยมชม
</p>
<p>4. ระยะเวลาการจัดเก็บคุกกี้
บริษัทฯ จะจัดเก็บคุกกี้ของท่านเป็นไปตามระยะเวลาที่จำเป็นหรือตามที่กฎหมายกำหนด
</p>
<p>5. การแก้ไขเปลี่ยนแปลงนโยบาย
บริษัทฯ อาจมีการเปลี่ยนแปลงและแก้ไขนโยบายฉบับนี้ โดยบริษัทฯ จะแจ้งนโยบายการใช้คุกกี้ฉบับปัจจุบันไว้ที่เว็บไซต์ของบริษัทฯ
</p>
<p>6. ประกาศความเป็นส่วนตัว
ท่านสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่านที่บริษัทฯ ได้มีการรวบรวม จัดเก็บ และใช้งาน โดยการเข้าเยี่ยมชมเว็บไซต์ของเรา
</p>
<p>7. การตั้งค่าคุกกี้
ท่านสามารถตั้งค่าคุกกี้ที่จำเป็นอย่างยิ่ง (Necessary Cookies) ได้ที่เว็บเบราว์เซอร์ (web browsers)  และสามารถจัดการความเป็นส่วนตัวของท่านได้เอง โดยคลิกที่ปุ่มตั้งค่า  เพื่อพัฒนาประสิทธิภาพ และประสบการณ์ที่ดีในการใช้เว็บไซต์ของท่าน ท่านสามารถศึกษารายละเอียดได้ที่ นโยบายคุ้กกี้
</p>

        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Instagram',

    data: () => ({
      posts: [
        { src: 'adventurealtitude.jpg' },
        { src: 'garden.jpg' },
        { src: 'pigduck.jpg' },
        { src: 'rain.jpg' },
        { src: 'spices.jpg' },
        { src: 'sunset.jpg' },
      ],
    }),
    methods: {
      selectLanguage (language) {
        console.log(language, 'language componnet Cookie-policy')
      },
    }
  }
</script>
